import { useBilling } from "@Dashboard/composables/billing";
import { usePage } from "@inertiajs/vue3";
import { Calendar, ChartArea, Images, QrCode, Settings, ShoppingCart, SquareMenu, Star, UsersRound } from "lucide-vue-next";
import { computed, type Component } from "vue";
import { route } from "ziggy-js";
import { useDateTime } from "./datetime";

type MenuItem = {
  title: string;
  url: string;
  icon?: Component;
  isActive?: boolean;
  items?: MenuItem[];
}

export default function useMenu() {
  const { subscribedToPlan } = useBilling()
  const { today, toISODate } = useDateTime()

  const user = computed(() => usePage().props.auth.user);
  const venue = computed(() => usePage().props.active_venue?.venue);

  function buildMenu(): MenuItem[] {
    if (!venue.value) {
      return []
    }

    return [
      ...route().current('projects.menus.*', venue.value.id)
        ? [{
          title: 'Menu',
          url: route('projects.menus.index', venue.value.id),
          icon: SquareMenu,
          isActive: route().current('projects.menus.*', venue.value.id),
          items: [
            {
              title: 'Editor',
              url: route('projects.menus.index', venue.value.id),
              isActive: route().current('projects.menus.*', venue.value.id) && !route().current('projects.menus.options.*', venue.value.id),
            },
            {
              title: 'Options',
              url: route('projects.menus.options.index', [venue.value.id, route().params.menu]),
              isActive: route().current('projects.menus.options.*', venue.value.id),
            },
          ],
        }]
        : [{
          title: 'Menu',
          url: route('projects.menus.index', venue.value.id),
          icon: SquareMenu,
          isActive: route().current('projects.menus.*', venue.value.id),
        }],
      ...subscribedToPlan('essential')
        ? [{
          title: 'Orders',
          url: route('projects.orders.index', venue.value.id),
          icon: ShoppingCart,
          isActive: route().current('projects.orders.*', venue.value.id),
        }]
        : [],
      {
        title: 'Guests',
        url: route('projects.guests.index', venue.value.id),
        icon: UsersRound,
        isActive: route().current('projects.guests.*', venue.value.id),
      },
      ...venue.value.settings.bookings.active
        ? [{
          title: 'Bookings',
          url: route('projects.bookings.calendar', venue.value.id),
          icon: Calendar,
          isActive: route().current('projects.bookings.*', venue.value.id),
          items: [
            {
              title: 'Calendar',
              url: route('projects.bookings.calendar', venue.value.id),
              isActive: route().current('projects.bookings.calendar', venue.value.id),
            },
            {
              title: 'Day',
              url: route('projects.bookings.day', [venue.value.id, toISODate(today())]),
              isActive: route().current('projects.bookings.day', venue.value.id),
            },
            {
              title: 'List',
              url: route('projects.bookings.list', venue.value.id),
              isActive: route().current('projects.bookings.list', venue.value.id),
            },
          ],
        }]
        : [],
      ...user.value.is_admin
        ? [{
          title: 'Reviews',
          url: route('projects.reviews.index', venue.value.id),
          icon: Star,
          isActive: route().current('projects.reviews.*', venue.value.id),
        }]
        : [],
      {
        title: 'Analytics',
        url: route('projects.analytics', venue.value.id),
        icon: ChartArea,
        isActive: route().current('projects.analytics*', venue.value.id),
      },
      {
        title: 'QR Codes',
        url: route('projects.qr-codes.index', venue.value.id),
        icon: QrCode,
        isActive: route().current('projects.qr-codes.*', venue.value.id),
      },
      {
        title: 'Settings',
        url: route('projects.settings', venue.value.id),
        icon: Settings,
        isActive: route().current('projects.settings*', venue.value.id) || route().current('projects.locations.*', venue.value.id) || route().current('projects.working-hours.*', venue.value.id) || route().current('projects.billing*', venue.value.id) || route().current('projects.integrations.*', venue.value.id) || route().current('projects.domains', venue.value.id) || route().current('projects.users.*', venue.value.id),
        items: [
          {
            title: 'General',
            url: route('projects.settings', venue.value.id),
            isActive: route().current('projects.settings', venue.value.id),
          },
          {
            title: 'Billing',
            url: route('projects.billing', venue.value.id),
            isActive: route().current('projects.billing*', venue.value.id),
          },
          {
            title: 'Location',
            url: route('projects.locations.index', venue.value.id),
            isActive: route().current('projects.locations.*', venue.value.id),
          },
          {
            title: 'Website',
            url: route('projects.settings.website', venue.value.id),
            isActive: route().current('projects.settings.website', venue.value.id),
          },
          {
            title: 'Gallery',
            url: route('projects.gallery.index', venue.value.id),
            isActive: route().current('projects.gallery.*', venue.value.id),
          },
          {
            title: 'Booking',
            url: route('projects.settings.booking', venue.value.id),
            isActive: route().current('projects.settings.booking*', venue.value.id) || route().current('projects.settings.shifts.*', venue.value.id),
          },
          ...user.value.is_admin ? [{
            title: 'Integrations',
            url: route('projects.integrations.index', venue.value.id),
            isActive: route().current('projects.integrations.*', venue.value.id),
          }] : [],
          {
            title: 'Custom domains',
            url: route('projects.domains', venue.value.id),
            isActive: route().current('projects.domains', venue.value.id),
          },
          {
            title: 'Users',
            url: route('projects.users.index', venue.value.id),
            isActive: route().current('projects.users.*', venue.value.id),
          },
        ],
      }
    ]
  }

  return { buildMenu }
}
