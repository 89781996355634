<script setup lang="ts">
import AppSidebar from '@Dashboard/components/app/AppSidebar.vue';
import { SidebarProvider } from '@Dashboard/components/ui/sidebar';
import { Modal } from 'momentum-modal';
import { onMounted, ref } from 'vue';

const isOpen = ref(true);

onMounted(() => {
  isOpen.value = localStorage.getItem('sidebar:state') !== 'false';
})

const handleSidebarChange = (open: boolean) => {
  isOpen.value = open;

  localStorage.setItem('sidebar:state', String(open));
}
</script>

<template>
  <SidebarProvider :default-open="isOpen" :open="isOpen" @update:open="handleSidebarChange">
    <AppSidebar />

    <slot />

    <Modal />
  </SidebarProvider>
</template>
