<script setup lang="ts">
import {
  Avatar,
  AvatarFallback
} from '@Dashboard/components/ui/avatar';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@Dashboard/components/ui/collapsible';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@Dashboard/components/ui/dropdown-menu';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
} from '@Dashboard/components/ui/sidebar';
import useMenu from '@Dashboard/composables/menu';
import { Link, usePage } from '@inertiajs/vue3';
import {
  ChevronRight,
  ChevronsUpDown,
  ExternalLink,
  LogOut,
  UserPen
} from 'lucide-vue-next';
import { computed } from 'vue';
import { route } from 'ziggy-js';
import NavMain from './NavMain.vue';
import VenueSwitcher from './VenueSwitcher.vue';

const { buildMenu } = useMenu()

const user = computed(() => usePage().props.auth.user)
const venue = computed(() => usePage().props.active_venue?.venue)
const userVenues = computed(() => usePage().props.user.venues)
const navigation = computed(() => buildMenu())
</script>

<template>
  <Sidebar collapsible="icon">
    <SidebarHeader>
      <VenueSwitcher
        v-if="venue"
        :selected-venue="venue"
        :venues="userVenues"
      />
      <NavMain />
    </SidebarHeader>

    <SidebarContent>
      <SidebarGroup>
        <SidebarGroupLabel>Venue</SidebarGroupLabel>
        <SidebarMenu>
          <template
            v-for="item in navigation"
            :key="item.title"
          >
            <Collapsible
              v-if="item.items"
              as-child
              :default-open="item.isActive"
              class="group/collapsible"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger as-child>
                  <SidebarMenuButton :tooltip="item.title">
                    <component :is="item.icon" />
                    <span>{{ item.title }}</span>
                    <ChevronRight class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    <SidebarMenuSubItem
                      v-for="subItem in item.items"
                      :key="subItem.title"
                    >
                      <SidebarMenuSubButton
                        as-child
                        :is-active="subItem.isActive"
                      >
                        <Link :href="subItem.url">
                        <span>{{ subItem.title }}</span>
                        </Link>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>

            <SidebarMenuItem v-else>
              <SidebarMenuButton
                as-child
                :is-active="item.isActive"
              >
                <Link :href="item.url">
                <component :is="item.icon" />
                <span>{{ item.title }}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </template>
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup
        v-if="venue"
        class="group-data-[collapsible=icon]:hidden mt-auto"
      >
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              as-child
              size="sm"
            >
              <a :href="venue.url">
                <ExternalLink />
                <span>View demo</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroup>
    </SidebarContent>

    <SidebarFooter>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <SidebarMenuButton
                size="lg"
                class="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <Avatar class="w-8 h-8 rounded-lg">
                  <!-- <AvatarImage :src="user.avatar" :alt="user.full_name" /> -->
                  <AvatarFallback class="rounded-lg">
                    {{ user.initials }}
                  </AvatarFallback>
                </Avatar>
                <div class="grid flex-1 text-sm leading-tight text-left">
                  <span class="font-semibold truncate">{{ user.full_name }}</span>
                  <span class="text-xs truncate">{{ user.email }}</span>
                </div>
                <ChevronsUpDown class="ml-auto size-4" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              class="w-[--reka-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              side="bottom"
              align="end"
              :side-offset="4"
            >
              <DropdownMenuLabel class="p-0 font-normal">
                <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                  <Avatar class="w-8 h-8 rounded-lg">
                    <!-- <AvatarImage :src="user.avatar" :alt="user.full_name" /> -->
                    <AvatarFallback class="rounded-lg">
                      {{ user.initials }}
                    </AvatarFallback>
                  </Avatar>
                  <div class="grid flex-1 text-sm leading-tight text-left">
                    <span class="font-semibold truncate">{{ user.full_name }}</span>
                    <span class="text-xs truncate">{{ user.email }}</span>
                  </div>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem as-child>
                  <Link :href="route('profile.show')">
                  <UserPen />
                  Profile
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem as-child>
                <Link
                  method="post"
                  :href="route('logout')"
                  class="w-full"
                >
                <LogOut />
                Log out
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>
    <SidebarRail />
  </Sidebar>
</template>
