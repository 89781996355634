import './bootstrap';

import '../../css/dashboard.css';

import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsLocaleData from 'dayjs/plugin/localeData';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayjsDuration);
dayjs.extend(dayjsLocaleData);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

// window.dayjs = dayjs;

import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from "@sentry/vue";
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { modal } from 'momentum-modal';
import { createApp, DefineComponent, h } from 'vue';
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/index';
import DashboardLayout from './Layouts/DashboardLayout.vue';
import i18n from './i18n';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'))

    page.default.layout = page.default.layout || DashboardLayout

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
    const isProduction = import.meta.env.PROD;

    if (isProduction) {
      Sentry.init({
        app,
        dsn: "https://ba9b3bdee35b470199fb85c1e5c640dd@o79684.ingest.sentry.io/6571413",
        environment: isProduction ? "Production" : "Development",
        integrations: [
          Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: isProduction ? 0.05 : 0,
        tracePropagationTargets: ["localhost", "dashboard.pushey.com", "dashboard.hollapp.com", "dashboard.pushey.test", /^\//],
        logErrors: true,
      });
    }

    app
      .use(plugin)
      .use(modal, {
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
      })
      .use(i18n)
      .use(VueGoogleMaps, {
        load: {
          key: 'AIzaSyBS46tOG5kOoQrvR9THUTGKkfA4dtrpyDM',
          libraries: 'places',
        },
      })
      .use(ZiggyVue, Ziggy)
      .component(VueQrcode.name!, VueQrcode)
      .mount(el);

    return app;
  },
  progress: {
    color: '#4B5563',
  },
});
