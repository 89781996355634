<script setup lang="ts">
import {
Collapsible,
CollapsibleContent,
CollapsibleTrigger,
} from '@Dashboard/components/ui/collapsible';
import {
SidebarMenu,
SidebarMenuButton,
SidebarMenuItem,
SidebarMenuSub,
SidebarMenuSubButton,
SidebarMenuSubItem
} from '@Dashboard/components/ui/sidebar';
import { Link, usePage } from '@inertiajs/vue3';
import { ChevronRight } from 'lucide-vue-next';
import { computed } from 'vue';

const user = computed(() => usePage().props.auth.user)
</script>

<template>
  <SidebarMenu class="group-data-[collapsible=icon]:hidden">
    <SidebarMenuItem>
      <SidebarMenuButton
        as-child
        :is-active="route().current('projects.index') || route().current('projects.create')"
      >
        <Link :href="route('projects.index')">
        <!-- <component :is="item.icon" /> -->
        <span>Restaurants</span>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>

    <Collapsible
      v-if="user.is_admin"
      as-child
      :default-open="route().current('admin.*')"
      class="group/collapsible"
    >
      <SidebarMenuItem>
        <CollapsibleTrigger as-child>
          <SidebarMenuButton tooltip="Admin">
            <span>Admin</span>
            <ChevronRight class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                as-child
                :is-active="route().current('admin.users.*')"
              >
                <Link :href="route('admin.users.index')">
                <span>Users</span>
                </Link>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>

            <SidebarMenuSubItem>
              <SidebarMenuSubButton
                as-child
                :is-active="route().current('admin.projects.*')"
              >
                <Link :href="route('admin.projects.index')">
                <span>Projects</span>
                </Link>
              </SidebarMenuSubButton>
            </SidebarMenuSubItem>
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  </SidebarMenu>
</template>
