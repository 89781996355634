import { fromDate } from "@internationalized/date"
import dayjs, { type Dayjs } from "dayjs"
import { useVenue } from "./venue"

export const useDateTime = () => {
  const venue = useVenue()

  function today() {
    return dayjs().startOf('day').tz(venue.timezone.value, true)
  }

  function parseDate(date: string) {
    return dayjs(date).tz(venue.timezone.value)
  }

  function toDate(date: Dayjs) {
    return date.format('DD.MM.YYYY')
  }

  function toTime(date: Dayjs) {
    return date.format('HH:mm')
  }

  function toDateTime(date: Dayjs) {
    return date.format('DD.MM.YYYY HH:mm')
  }

  function toISODate(date: Dayjs) {
    return date.format('YYYY-MM-DD')
  }

  function toZonedDateTime(date: Dayjs) {
    return fromDate(date.toDate(), venue.timezone.value)
  }

  return { today, parseDate, toDate, toTime, toDateTime, toISODate, toZonedDateTime }
}
