import { usePage } from '@inertiajs/vue3';

export const useBilling = () => {
  /**
   * Determine if the project model is actively subscribed to one of the given plans.
   */
  function subscribedToPlan(plans: string | string[]): boolean {
    const codes = Array.isArray(plans) ? plans : [plans];
    const venuePlans = usePage().props.active_venue?.billing?.plans || []

    for (const plan of codes) {
      if (venuePlans.includes(plan)) {
        return true;
      }
    }

    return false;
  }

  return {
    subscribedToPlan,
  };
}
