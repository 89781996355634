import { usePage } from "@inertiajs/vue3"
import { computed } from "vue"

export const useVenue = () => {
  const venue = computed(() => usePage().props.active_venue?.venue)
  const timezone = computed(() => venue.value?.timezone || 'UTC')
  const country = computed(() => venue.value?.location?.country?.alpha_2)

  return { timezone, country }
}
